(function ($) {
	$(document).ready(function () {
		var modalPopup = document.querySelector('.v-popup');		

		if (modalPopup) {
			var body = document.body;
			var popup = document.querySelector('.v-popup');
			var closePopUp = modalPopup.querySelector('.v-popup__close-btn');
			var popUpOverlay = modalPopup.querySelector('.v-popup__overlay');
			var isHome = document.querySelector('body').classList.contains('home');

			function showPopup() {				
				var modalID = modalPopup.dataset.id;
				// console.log('Modal ID:', modalID);
				// console.log('Session Storage Key:', 'klnb-modal-' + modalID);
				// console.log('Session Storage Value:', sessionStorage.getItem('klnb-modal-' + modalID));

				if (!modalPopup || !modalID) {
					return;
				}
				if (!modalID) return;

				var wasDismissed = sessionStorage.getItem('klnb-modal-' + modalID);
				// console.log('Was Dismissed:', wasDismissed);

				modalID = modalID.replace(' ', '-');
				body.style.overflow = 'hidden';

				if (wasDismissed === 'dismissed') {
					body.style.overflow = 'auto';
					modalPopup.classList.add('v-popup--hide');

					// call the fn in front-page.js
					// (triggers hero animations)
					if (isHome) {
						// Only call vFrontPage() if on the home page
						vAlert = false;
						vFrontPage();
					}
				return;
				}

				modalPopup.classList.remove('v-popup--hide');
				sessionStorage.setItem('klnb-modal-' + modalID, 'seen');
			}
			showPopup();

			function closePopup() {				
				var modalID = modalPopup.dataset.id;

				if (!popup) return;

				body.style.overflow = 'auto';
				popup.classList.add('v-popup--hide', 'v-popup--close');
				popup.querySelector('.v-popup__container').classList.add('v-popup__container--hide');

				// call the fn in front-page.js
				// (triggers hero animations)
				if (isHome) {
					// Only call vFrontPage() if on the home page
					vAlert = false;
					vFrontPage();
				}

				sessionStorage.setItem('klnb-modal-' + modalID, 'dismissed');
			}

			closePopUp.addEventListener('click', function () {
				closePopup();
			});

			popUpOverlay.addEventListener('click', function () {
				closePopup();
			});

			$(document).keyup(function(e) {
				if (e.key === "Escape") {
					closePopup();
				}
			});
		};
	});
})(jQuery);
